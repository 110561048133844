import PricingCard from "./PricingCard";
import { proFeatures, trialFeatures } from "./pricingContants";

const Pricing = () => {
  return (
    <div id="pricing" className="relative">
      <div className="w-[100%] h-[100%] absolute -z-10 flex items-center justify-center">
        <div className="h-[100%] w-[100%] backdrop-blur-3xl bg-gradient-to-r from-primary-100 to-primary-800 blur-xl opacity-10"></div>
      </div>
      <div className="w-[100%] px-10 py-20 flex flex-col items-center text-center text-balance z-10">
        <p className="w-[80%] lg:w-[40%] font-bold text-xs opacity-80">
          SIMPLE AND STRAIGHT FORWARD PRICING
        </p>
        <h1 className="w-[80%] lg:w-[40%] font-bold text-3xl md:text-4xl">
          One <span className="font-mono text-primary-900">plan</span> to rule
          them all.
        </h1>
        <span className="w-[80%] lg:w-[40%] text-lg mt-10 text-zinc-800 font-light">
          Notsly comes with straight forward pricing. Early bird members will
          get exclusive discounted prices. Prices go up every 100 users.
        </span>

        <div className="flex gap-5 flex-wrap justify-center flex-grow">
          <PricingCard
            packageName="TRIAL"
            features={trialFeatures}
            pricingSection={
              <div className="mt-5">
                <span>
                  <span className="font-bold text-3xl mt-3">$1</span> for 30
                  days
                </span>
              </div>
            }
            href="/auth"
          />
          <PricingCard
            packageName="EARLY ADOPTERS"
            features={proFeatures}
            pricingSection={
              <div>
                <p className="text-xs font-extralight">
                  <span className="line-through font-bold text-3xl">$9</span>{" "}
                  100/100 signs up ✅
                </p>
                <div className="mt-5">
                  <span>
                    <span className="font-bold text-3xl mt-3">$29</span> for
                    Lifetime
                  </span>
                  <p className="text-xs font-extralight">
                    Price increases to <span className="font-bold">$39</span>{" "}
                    soon
                  </p>
                </div>
              </div>
            }
            href="/auth"
          />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
