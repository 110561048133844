"use client";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  UseDisclosureProps,
} from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import { FaCheck } from "react-icons/fa6";
import { BiRocket } from "react-icons/bi";
import { useEffect, useState } from "react";
import { proFeaturesOnly } from "./pricingContants";

const UpgradeToProModal = (
  props: {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
  } & UseDisclosureProps
) => {
  const { isOpen, onClose } = props;
  const [userDetails, setUserDetails] = useState<{
    email?: string;
    name?: string;
    id?: string;
  }>({});

  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    setUserDetails(
      JSON.parse(userDetails || "{}") as {
        email?: string;
        name?: string;
        id?: string;
      }
    );
  }, []);

  const generatePaymentLink = (url: string) => {
    return `${url}?checkout[email]=${userDetails?.email}&checkout[name]=${userDetails?.name}&checkout[custom][userId]=${userDetails?.id}`;
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent minW="50%">
          <ModalCloseButton />
          {!userDetails && <Skeleton />}
          <ModalBody className="w-[100%]">
            <div className="flex gap-10 p-3 flex-wrap justify-center">
              <div className="flex flex-col max-xl:items-center max-xl:text-center w-[320px]">
                <Image
                  src="/logo-full.svg"
                  className="hover:scale-110 max-w-[96px] w-[96px] max-h-[48px] transition-all"
                  alt="logo"
                  width={48}
                  height={48}
                  priority
                />
                <div className="flex items-center flex-grow justify-center text-3xl font-bold">
                  <span className="inline-flex animate-text-gradient bg-gradient-to-r from-primary-200 via-primary-400 to-primary-800 bg-[200%_auto] bg-clip-text text-transparent">
                    Supercharge your note taking with Pro
                  </span>
                </div>
              </div>
              <div className="p-5 bg-primary-50 border-2 border-primary-300 w-[320px] rounded-xl">
                <h1 className="text-2xl font-semibold">Upgrade your plan</h1>
                <h1 className="mt-5 font-light text-xs">
                  <span className="font-bold text-3xl mt-3">$29</span> for
                  Lifetime
                </h1>
                <div className="mt-5">
                  {proFeaturesOnly.map((feature, index) => (
                    <p
                      className="text-sm font-medium flex items-center gap-3 mt-3"
                      key={index}
                    >
                      {feature && <FaCheck className="text-primary-500" />}
                      {feature || <pre>&nbsp;</pre>}
                    </p>
                  ))}
                </div>
                <Link
                  href={generatePaymentLink(
                    process.env.NEXT_PUBLIC_PAYMENT_PAGE_LINK!
                  )}
                  className="mt-5 w-[100%] px-3 py-1 shadow rounded-xl gap-3 bg-primary-800 hover:bg-primary-900 text-white flex items-center justify-center"
                >
                  <BiRocket />
                  <span className="font-semibold text-lg">Upgrade</span>
                </Link>
                <p className="text-xs font-light mt-5 text-center">
                  You will be charged $29 once. No recurring subscription.
                  Please check our{" "}
                  <Link
                    href="/terms-and-conditions"
                    className="font-bold hover:underline hover:cursor-pointer"
                  >
                    Terms and Conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="/privacy-policy"
                    className="font-bold hover:underline hover:cursor-pointer"
                  >
                    Privacy Policy
                  </Link>
                  .
                </p>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpgradeToProModal;
